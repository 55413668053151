.proposals{
    padding-top: 64px;
    min-height: calc(100vh - 85px);
    .ant-layout-content{
        padding: 50px;
        @media (max-width: 767px) {
            padding: 20px;
        }
    }
    .head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 767px) {
            flex-direction: column;
            &>div{
                width: 100%;
            }
        }
        .ant-btn{
            background: #124FC7;
            border-radius: 8px;
            height: 52px;
            width: 320px;
            font-size: 18px;
            border: none;
            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }
    .content{
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        margin-top: 20px;
        @media (max-width: 767px) {
            grid-template-columns: 1fr;
        }
        grid-gap: 24px;
        .left{
            .card{
                background: linear-gradient(180deg, #4880EE 0%, #124FC7 100%);
                border-radius: 16px;
                padding: 24px;
            }
            h2{
                color: #FBFBFB;
                font-size: 24px;
            }
            .ant-select{
                color: #fff; 
                font-size: 16px;
                background: none;
                .ant-select-selector{
                    background: none;
                }
                .ant-select-arrow{
                    color: #fff;
                }
            }
            .filter{
                display: flex;
                justify-content: space-between;
                margin-bottom: 16px;
            }
            .ant-skeleton{
                padding: 16px;
                border-radius: 16px;
                background-color: #fff;
                &:not(:last-of-type){
                    margin-bottom: 16px;
                }
            }
            .item{
                background: #fff;
                box-shadow: 0px 0px 2px rgba(130, 130, 130, 0.3);
                border-radius: 16px;
                padding: 16px;
                display: grid;
                grid-template-columns: 1fr 50px;
                @media (max-width: 767px) {
                    grid-template-columns: 1fr 30px;
                }
                align-items: center;
                cursor: pointer;
                &:not(:last-of-type){
                    margin-bottom: 16px;
                }
                &>div{
                    &:last-of-type{
                        text-align: center;
                        .ant-btn{
                            border: none;
                            box-shadow: none;
                        }
                    }
                }
                h1{
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    .ant-tag{
                        background: #E1EBFE;
                        border-radius: 8px;
                        border: none;
                        width: 100px;
                        height: 28px;
                        line-height: 28px;
                        text-align: center;
                        font-size: 18px;
                        color: #1A1E27;
                    }
                }
                .status{
                    display: grid;
                    grid-template-columns: 1fr 116px;
                    grid-gap: 16px;
                    width: 100%;
                    @media (max-width: 767px) {
                        grid-template-columns: 1fr 78px;
                    }
                    .end_time{
                        background: #EFF2F5;
                        border-radius: 15px;
                        padding: 5px 10px;
                        .ant-statistic-content{
                            font-weight: 500;
                            font-size: 14px;
                            color: #1A1E27;
                            @media (max-width: 767px) {
                                font-size: 12px;
                            }
                        }
                    }
                    .ant-tag{
                        border-radius: 20px;  
                        padding: 5px;
                        width: 100%;
                        border: none;
                        color: #fff;
                        font-weight: 500;
                        font-size: 18px;
                        text-align: center;
                        &.soon{
                            background: linear-gradient(90deg, #4880EE -20.5%, #D242EA 111.5%);
                        }
                        &.active{
                            background: linear-gradient(90deg, #4880EE -20.5%, #7142EA 111.5%);
                        }
                        &.failed{
                            background: #9A9A9A;
                        }
                        &.passed{
                            background: linear-gradient(90deg, #4880EE -20.5%, #0DBAF1 111.5%);
                        }
                    }
                }
                h1{
                    color: #1A1E27;
                    font-size: 18px;
                }
                p{
                    font-size: 14px;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .ant-pagination{
                text-align: center;
                margin-top: 20px;
            }
        }
        .right{
            .card{
                background: #FFFFFF;
                border-radius: 16px;
                padding: 24px;
            }
            ol{
                background: #F6F6F6;
                border-radius: 16px;
                padding: 16px 16px 16px 36px;
                li{
                    color: #353C49;
                    font-weight: 500;
                    font-size: 16px;
                }
            }
            ul{
                background: #E1EBFE;
                list-style: none;
                border-radius: 16px;
                padding: 16px;
                margin-top: 16px;
                li{
                    font-weight: 500;
                    font-size: 16px;
                    color: #353C49;
                }
            }
        }
    }
}
.createProposal{
    padding-top: 64px;
    min-height: calc(100vh - 85px);
    .ant-layout-content{
        padding: 50px 200px;
        @media (max-width: 767px) {
            padding: 20px;
        }
        .react-mde{
            border-radius: 8px;
            overflow: hidden;
            .mde-header{
                border-radius: 8px 8px 0 0;
            }
        }
        .option{
            margin-bottom: 16px;
            .ant-col{
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .ant-btn-default{
            border: 1px solid #4880EE;
            border-radius: 8px;
            height: 50px;
            @media (max-width: 767px) {
                height: 40px;
            }
            color: #4880EE;
        }
        .ant-form-item-label{
            text-align: left;
            &>label{
                font-size: 18px;
                font-weight: 400;
                line-height: 48px;
                height: 48px;
                display: inline-block;
                @media (max-width: 767px) {
                    line-height: 40px;
                    height: 40px;
                }
                color: #1A1E27;
            }
        }
        .ant-input,.ant-picker-range{
            height: 48px!important;
            font-size: 16px;
            font-weight: 400;
            color: #353C49;
            width: 100%;
            @media (max-width: 767px) {
                height: 40px!important;
            }
        }
        .ant-btn-primary{
            background-color: #4880EE;
            border-radius: 8px;
            height: 50px;
            color: #fff;
            @media (max-width: 767px) {
                height: 40px;
            }
        }
        .info{
            &>div{
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #1A1E27;
                margin-bottom: 16px;
                &>:first-of-type{
                    font-size: 18px;
                    font-weight: 400;
                }
                &>:last-of-type{
                    font-size: 24px;
                    font-weight: 500;
                }
            }
        }
    }
}
.proposal-detail{
    padding-top: 64px;
    min-height: calc(100vh - 85px);
    .ant-layout-content{
        padding: 50px;
        @media (max-width: 767px) {
            padding: 20px;
        }
    }
    .back-btn{
        border: 1px solid #4880EE!important;
        width: 280px;
        background: none;
        text-align: left;
        height: 40px;
        line-height: 38px;
        padding: 0 20px;
    }
    .card{
        background: #FFF;
        border-radius: 16px;
        padding: 24px;
    }
    .detail{
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        grid-gap: 24px;
        @media (max-width: 767px) {
            grid-template-columns: 1fr;
        }
        h1{
            color: #1A1E27;
            font-weight: 500;
            font-size: 20px;
        }
        .content{
            word-break: break-all;
            @media (max-width: 767px) {
                display: none;
            }
        }
        .content-h5{
            display: none;
            grid-column-start: 1;
            grid-column-end: 3;
            @media (max-width: 767px) {
                display: block;
            }
            .ant-collapse{
                border: none;
            }
            .ant-collapse > .ant-collapse-item,.ant-collapse-content{
                border: none;
            }
            .ant-collapse > .ant-collapse-item > .ant-collapse-header{
                height: 32px;
                line-height: 1;
            }
            .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
                position: relative;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%); 
            }
        }
        .status{
            display: grid;
            grid-template-columns: 1fr 116px;
            grid-gap: 16px;
            width: 100%;
            .end_time{
                background: #EFF2F5;
                border-radius: 15px;
                padding: 5px 10px;
                .ant-statistic-content{
                    font-weight: 500;
                    font-size: 14px;
                    color: #1A1E27;
                }
            }
            .ant-tag{
                border-radius: 20px;  
                padding: 5px 20px;
                width: 100%;
                border: none;
                color: #fff;
                font-weight: 500;
                font-size: 18px;
                text-align: center;
                &.soon{
                    background: linear-gradient(90deg, #4880EE -20.5%, #D242EA 111.5%);
                }
                &.active{
                    background: linear-gradient(90deg, #4880EE -20.5%, #7142EA 111.5%);
                }
                &.failed{
                    background: #9A9A9A;
                }
                &.passed{
                    background: linear-gradient(90deg, #4880EE -20.5%, #0DBAF1 111.5%);
                }
            }
        }
        .results{
            background: #DBE3F0;
            border-radius: 16px;
            padding: 24px;
            margin-top: 24px;
            h2{
                font-weight: 500;
                font-size: 20px;
                color: #1A1E27;
            }
            .options{
                &>div{
                    background: #fff;
                    border-radius: 16px;
                    padding: 16px;
                    box-shadow: 0px 0px 2px rgba(130, 130, 130, 0.3);
                    &:not(:last-of-type){
                        margin-bottom: 10px;
                    }
                    .percent{
                        display: grid;
                        grid-template-columns: 1fr 120px;
                        align-items: center;
                        grid-gap: 15px;
                        &>div{
                            &:last-of-type{
                                text-align: right;
                            }
                        }
                        .ant-progress-status-success .ant-progress-text{
                            color: #000;
                        }
                    }
                }
            }
            .ant-btn{
                color: #2C6CEB;
                font-size: 18px;
                text-align: center;
                padding: 16px;
            }
        }
        .right-content{
            &>div{
                &:not(:last-of-type){
                    margin-bottom: 20px;
                }
            }
            h2{
                font-weight: 500;
                font-size: 20px;
                color: #1A1E27;
            }
            .votes{
                .ant-radio-group,.ant-space-vertical{
                    width: 100%;
                }
                .ant-radio-wrapper{
                    background: #fff;
                    border: 1px solid #CFD6E4;
                    border-radius: 8px;
                    padding: 16px;
                    width: 100%;
                    font-size: 14px;
                    color: #353C49;
                }
                .ant-statistic-content{
                    color: #1A1E27;
                    font-size: 16px;
                    text-align: center;
                    margin-top: 10px;
                }
            }
            .your_voted{
                display: flex;
                justify-content: space-between;
                font-weight: 500;
                font-size: 18px;
                color: #1A1E27;
                padding: 10px 0;
            }
            .ant-btn{
                border-radius: 8px;
                height: 50px;
                font-size: 18px;
                border: none;
                @media (max-width: 767px) {
                    height: 40px;
                }
                &[disabled]{
                    background: #B8C5D8;
                    color: #fff;
                }
            }
            .choose{
                .option{
                    background: #F6F6F6;
                    border-radius: 8px;
                    padding: 8px 16px;
                    font-size: 16px;
                    color: #353C49;
                }
                .count{
                    display: flex;
                    justify-content: space-between;
                    padding: 16px 0;
                    font-weight: 500;
                    font-size: 18px;
                    color: #1A1E27;
                }
            }
            .details{
                margin-top: 20px;
                .info{
                    background: #F6F6F6;
                    border-radius: 16px;
                    padding: 16px;
                    &>div{
                        display: flex;
                        justify-content: space-between;
                        font-size: 16px;
                        color: #353C49;
                        margin: 8px;
                    }
                }
            }
        }
    }
}
.confirmModal{
    .option{
        padding: 16px;
        background: #EBF2FE;
        border-radius: 8px;
        font-size: 14px;
    }
    .count{
        display: flex;
        justify-content: space-between;
        padding: 16px 0;
        font-weight: 500;
        font-size: 18px;
        color: #1A1E27;
    }
    .notice{
        background: #F6F6F6;
        border-radius: 8px;
        padding: 8px;
        font-size: 12px;
    }
    .ant-modal-footer{
        border-top: none;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;
        padding: 0 24px 24px;
        .ant-btn{
            border-radius: 8px;
            height: 50px;
            font-size: 18px;
            width: 100%;
            @media (max-width: 767px) {
                height: 40px;
            }
        }
    }
}
.votesModal{
    .ant-table{
        .ant-table-thead{
            display: none;
        }
        .ant-table-tbody > tr > td{
            border-bottom: none;
            padding:5px 0;
        }
    }
}
@primary-color: #4880EE;@border-radius-base: 8px;