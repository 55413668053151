.wallet-page{
    padding-top: 64px;
    min-height: calc(100vh - 85px);
    .ant-layout-content{
        padding: 50px;
        @media (max-width: 767px) {
            padding: 20px;
        }
    }
    .info{
        background: linear-gradient(89.89deg, #4880EE 0.01%, #A347FF 99.84%);
        box-shadow: 0px 4px 4px rgba(85, 103, 214, 0.3);
        border-radius: 24px;
        padding: 30px;
        width: 100%;
        display: grid;
        grid-template-columns: 1.8fr 1fr;
        grid-gap: 100px;
        @media (max-width: 767px) {
            grid-template-columns: 1fr;
            grid-gap: 8px;
            padding: 8px;
        }
        .left-content{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;
        }
        .address{
            color: #fff;
            font-size: 20px;
            line-height: 38px;
            display: flex;
            align-items: center;
            width: 100%;
            overflow: hidden;
            @media (max-width: 767px) {
                font-size: 12px;
            }
            &>div{
                &:first-of-type{
                   svg{
                       display: block;
                   } 
                }
                &:last-of-type{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .address_link{
                        word-break: break-all;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .ant-btn{
                        color: #fff;
                        font-size: 20px;
                        background: none;
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;
                        & > span{
                            display: flex;
                        }
                        svg{
                            width: 26px;
                            height: auto;
                            @media (max-width: 767px) {
                                width: 20px;
                            }
                        }
                    }
                }
            }

            .wallet{
                height: 38px;
                width: auto;
                fill: none;
                margin-right: 10px;
                @media (max-width: 767px) {
                    height: 25px;
                }
            }
        }
        .btns{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;
            .ant-btn{
                border-radius: 8px;
                height: 50px;
                font-size: 20px;
                border: none;
                @media (max-width: 767px) {
                    height: 32px;
                    font-size: 13px;
                }
            }
            .ant-btn-default{
                background: #fff;
                color: #4880EE;
            }
            .ant-btn-dangerous{
                background: #FF7D7D;
                color: #fff;
            }
        }
        .assets{
            background: #8343CF;
            border-radius: 16px;
            color: #fff;
            padding: 10px 30px;
            &>div{
                text-align: center;
                padding: 10px 0;
                &:first-of-type{
                    font-size: 20px;
                    @media (max-width: 767px) {
                        font-size: 14px;
                    }
                    border-bottom: 1px solid #fff;
                }
                &:last-of-type{
                    font-size: 30px;
                    @media (max-width: 767px) {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    h1{
        font-size: 32px;
        color: #1A1E27;
        margin: 10px 0;
        font-weight: bold;
        @media (max-width: 767px) {
            font-size: 18px;
        }
    }
    h2{
        font-size: 24px;
        color: #1A1E27;
        font-weight: 500;
        margin: 50px 0 30px;
        @media (max-width: 767px) {
            font-size: 16px;
            margin: 26px 0 10px;
        }
    }
    .ant-table{
        .ant-table-thead > tr > th{
            background: #124FC7;
            color: #fff;
        }
        .ant-table-tbody > tr{
            cursor: pointer;
        }
        .ant-table-cell-ellipsis{
            white-space: inherit;
        }
    }
    .asset{
        display: flex;
        align-items: center;
        img{
            height: 24px;
            width: 24px;
            margin-right: 10px;
        }
        svg{
            background: #2175FF;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            padding: 3px;
            margin-right: 10px;
        }
    }
}
@primary-color: #4880EE;@border-radius-base: 8px;