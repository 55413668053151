.claim {
    width: 100%;
    padding: 20px 0;

    .ant-btn {
        margin-bottom: 20px;
    }

    h2 {
        font-size: 20px;
        font-weight: 500;
        color: #1A1E27;
        margin: 20px 0px 10px !important;
    }

    .claim-info {
        background: #F6F6F6;
        border-radius: 16px;
        padding: 10px 20px;

        .ant-tag {
            border: 1px solid #A8B0C1 !important;
            font-size: 14px !important;
            color: #4880EE !important;
            background: #fff !important;
            border-radius: 8px !important;
            width: 32px !important;
            height: 32px !important;
            line-height: 30px !important;
            display: inline-block !important;
            margin-bottom: 0 !important;
        }

        &>div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0;

            &>div {
                &:first-of-type {
                    color: #1A1E27;
                    font-size: 16px;
                    font-weight: 400;
                }

                &:last-of-type {
                    color: #1A1E27;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
}

.ticket {
    display: inline-flex;
    background-color: #4880EE;
    color: white;
    min-width: 20px;
    padding: 0px 3px;
    height: 20px;
    font-size: 10px;
    text-align: center;
    vertical-align: middle;
    border-radius: 5px;
    margin-right: 3px;
    align-items: center;
    justify-content: center;

    .winning {
        background-color: red;
    }
}

.ticket_label {
    flex: none;
    width: 128px;
}

.claim_text {
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
}
@primary-color: #4880EE;@border-radius-base: 8px;