.radio {
  background: #F6F6F6;
  border-radius: 20px;
  padding: 5px;
  line-height: 1;

  .ant-radio-button-wrapper {
    border: none !important;
    padding: 0 5px;
    background: transparent;
    font-weight: 600;
    width: 83px;
    //width: 63px;
    height: 30px;
    line-height: 30px;
    color: #000;
    font-size: 14px;
    text-align: center;
  }

  .ant-radio-button-wrapper-checked {
    background: #fff;
    border-radius: 20px;
    width: 63px;
    border: none;
    color: #000 !important;
  }

  .ant-radio-button-wrapper:focus-within {
    box-shadow: none !important;
  }

  // 去掉中间的分割线
  .ant-radio-button-wrapper:not(:first-child)::before {
    content: '';
    background-color: transparent;
  }
}

.ant-layout-header {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  width: 100%;
  line-height: 64px;
  padding: 0;
}

.header {
  background: none;
  width: 100%;
  display: grid;
  grid-template-areas: "logo nav right";
  grid-template-columns: 140px 1fr auto;
  background-color: none;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 50px;

  @media (max-width: 992px) {
    grid-template-columns: 100px 1fr auto;
    padding: 0 20px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  @media (max-width: 767px) {
    width: 100%;
    grid-template-areas: "burger logo""nav nav";
    grid-template-columns: 40px 1fr auto;

    .Nav a {
      height: 50px;
      line-height: 50px;
      padding: 0;

    }
  }

  .ant-select {
    border: 1px solid #81ABFF;
    border-radius: 8px;
    margin-right: 10px;
    color: #ffffff;
    width: 140px;

    @media (max-width: 992px) {
      width: 100px;
    }

    @media (max-width: 767px) {
      color: #000;
      width: 140px;
    }

    &-arrow {
      color: #fff;

      @media (max-width: 767px) {
        color: #000;
      }
    }

    .ant-select-selector {
      background: none;
      border: none;
      line-height: 38px;
      height: 38px;
      padding: 0 16px;

      .ant-select-selection-item {
        line-height: 38px;
        height: 38px;
      }
    }
  }

  .Nav {
    display: flex;
    align-items: center;

    a {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      transition: 0.5s;
      padding: 10px 0;
      margin: 0 20px;
      text-decoration: none;
      line-height: 1;

      @media (max-width: 992px) {
        margin: 0 8px;
      }

      &.active {
        font-weight: bold;
        color: #fff;
        border-bottom: 2px solid #fff;
        padding: 10px;
        padding-bottom: 15px;
      }
    }

    &.right {
      a {
        padding: 0 10px;
      }
    }

    @media (max-width: 767px) {
      &.left {
        top: 64px;
        left: 0;
        grid-template-rows: repeat(4, 1fr);
      }

      &.right {
        top: 214px;
        left: 0;
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 10px;
        padding-bottom: 10px;
      }

      display: flex;
      flex-direction: column;
      padding: 0;
      background: #fff;
      width: 100%;
      position: absolute;
      z-index: 100;
      margin-right: 0;
      align-items: center;
      justify-items: flex-start;
      border-bottom: 1px solid #ddd;

      a {
        color: #000 !important;
        line-height: 50px;
        padding: 0;
        text-align: center;
        display: block;
      }

      button {
        align-items: flex-end;
      }
    }

    .ant-btn {
      a {
        color: #fff;
      }
    }
  }

  .Logo {
    float: left;
    width: auto;
    height: 30px;
    margin: 17px 0;
    grid-area: logo;

    @media (max-width: 992px) {
      height: 20px;
      margin: 22px 0;
    }

    @media (max-width: 767px) {
      height: 20px;
      margin: 22px 0;
    }
  }

  .Burger {
    display: none;
    grid-area: burger;
    margin: 0;
    padding: 0;
    justify-self: self-start;
    font-size: 25px;
    border: none;
    background: none;
    outline: none;
    transition: 0.1s;

    @media (max-width: 767px) {
      display: inline;
    }
  }

  .wallet-icon {
    background: none;
    border: none;
    display: none;
    justify-content: center;
    align-items: center;

    @media (max-width: 767px) {
      display: flex;
    }

    svg {
      fill: none;
    }
  }

  .NavAnimation-enter {
    opacity: 0;
    transform: scale(0.5);
  }

  .NavAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 350ms, transform 350ms;
  }

  .NavAnimation-exit {
    opacity: 1;
  }

  .NavAnimation-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 350ms, transform 350ms;
  }

}

.pc_chorme_message {
  height: 40px;
  background-color: #1239A3;
  text-align: center;
  display: flex;
  font-size: 17px;
  color: white;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  @media (max-width: 767px) {
    min-height: 25px;
    font-size: 12px;
    line-height: 1;
    padding: 2px 0px;
    box-sizing: border-box;
  }
}
@primary-color: #4880EE;@border-radius-base: 8px;