.redeem{
    width: 100%;
    .tickets{
        display: grid;
        grid-template-columns: 1fr;
        margin: 0 25%;
        grid-gap: 30px;
        align-items: center;
        margin-bottom: 20px;
        &>div{
            display: flex;
            justify-content: space-between;
            line-height: 38px;
            font-size: 24px;
            color: #1A1E27;
            &:first-of-type{
                display: flex;
                justify-content: space-between;
                line-height: 38px;
                font-size: 24px;
                color: #1A1E27;
            }
            &:last-of-type{
                text-align: right;
            }
        }
        .ant-input-number{
            font-size: 24px;
            color: #1A1E27;
            input{
                text-align: center;
            }
        }
        button{
            background: #FFFFFF;
            border: 1px solid #C7C7C7;
            border-radius: 8px;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            cursor: pointer;
        }
    }
    .pay-info{
        background: #F6F6F6;
        border-radius: 16px;
        padding: 8px;
        margin-bottom: 20px;
        .ant-tag{
            display: block;
            width: 100%;
            background: #E9E9E9;
            border-radius: 20px;
            height: 40px;
            color: #1A1E27;
            font-size: 20px;
            border: none;
            line-height: 40px;
            text-align: center;
            margin-bottom: 0!important;
        }
        .ethPay{
            color: #1A1E27;
            font-size: 24px;
            text-align: center;
            line-height: 2.5;
            font-weight: 500;
        }
        .ethBalance{
            color: #505866;
            font-size: 16px;
            text-align: center;
            line-height: 1.2;
            font-weight: 500;
        }
    }
    .ant-btn{
        height: 52px;
    }
    .ant-statistic{
        background: linear-gradient(90deg, #4880EE -20.5%, #D242EA 111.5%);
        border-radius: 26px;
        .ant-statistic-content{
            color: #fff;
            text-align: center;
            height: 52px;
            line-height: 52px;
        }
    }
    .redeem-info{
        background: #F7F7F7;
        border-radius: 16px;
        padding: 8px 16px;
        margin: 20px 0;
        &>div{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            &>div{
                &:first-of-type{
                    color: #1A1E27;
                    font-size: 16px;
                }
                &:last-of-type{
                    color: #1A1E27;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
        .ant-btn-default{
            line-height: 40px;
            height: 40px;
            border: none;
            padding: 0;
            background: #EAEDF0!important;
            border-radius: 8px!important;
            font-size: 16px!important;
            color: #4880EE!important;
        }
        .ticketNo{
            display: block;
            .ant-tag{
                border: 1px solid #A8B0C1!important;
                font-size: 14px!important;
                color: #4880EE!important;
                background: #fff!important;
                border-radius: 8px!important;
                width: 32px!important;
                height: 32px!important;
                line-height: 30px!important;
                display: inline-block!important;
            }
        }
    }
}
@primary-color: #4880EE;@border-radius-base: 8px;