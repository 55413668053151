.staking {
    min-height: calc(100vh - 90px);

    .ant-layout-content {
        margin-top: -180px;
        margin-bottom: 60px;
        position: relative;
        z-index: 2;

        h1 {
            font-size: 24px;
            color: #000;
            margin: 60px 0 30px;

            &:first-of-type {
                color: #fff;
            }
        }
    }

    .first-content {
        background: url('../../assets/img/bg1.jpg') no-repeat, linear-gradient(90deg, #4880EE 0%, #155BE3 100%);
        background-size: 100%;
        height: 430px;
        background-position: 0 100%;
        padding-top: 64;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 767px) {
            background: linear-gradient(90deg, #4880EE 0%, #155BE3 100%);
        }

        .ant-layout-content {
            margin-top: 0;
            margin-bottom: 0;
        }

        h1 {
            font-weight: bold;
            font-size: 24px;
            color: #fff;
            margin: 0;
        }

        p {
            font-size: 18px;
            color: #fff;
        }

        .search {
            margin: 30px 0;

            .ant-input-affix-wrapper {
                border: none;
                height: 48px;
                font-size: 16px;

                &:focus,
                .ant-input-affix-wrapper-focused {
                    box-shadow: none;
                }
            }

            .ant-input-affix-wrapper-focused {
                box-shadow: none;
            }

            .ant-input-group-addon {
                border: none;
                background: #fff;

                .ant-btn {
                    border: none;
                }
            }
        }
    }

    .content {
        display: grid;
        grid-template-columns: 1.5fr 1fr;

        @media (max-width: 767px) {
            grid-template-columns: 1fr;
        }

        grid-gap: 24px;

        .card {
            background: #FFF;
            border-radius: 16px;
            padding: 16px;
        }

        .left-content {
            @media (max-width: 767px) {
                grid-row-start: 2;
                grid-row-end: 3;
            }

            .btns {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 20px;
                margin-bottom: 20px;

                .ant-btn {
                    border-radius: 8px;
                    height: 50px;
                    font-size: 18px;
                    border: none;

                    @media (max-width: 767px) {
                        height: 40px;
                    }
                }

                .ant-btn-primary {
                    background: #124FC7;
                }

                .ant-btn-default {
                    color: #4880EE;
                }
            }

            .stake_number {
                background: linear-gradient(89.89deg, #4880EE 0.01%, #A347FF 99.84%);
                box-shadow: 0px 4px 8px rgba(64, 72, 116, 0.4);
                border-radius: 24px;
                padding: 20px;
                color: #fff;

                &>div {
                    &:first-of-type {
                        font-size: 20px;
                        text-align: left;
                    }

                    &:last-of-type {
                        font-size: 52px;
                        text-align: center;
                    }
                }
            }

            .rank {
                margin-top: 20px;
                background: #EFF2F5;
                border-radius: 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 32px;

                &>div {
                    &:first-of-type {
                        color: #4880EE;
                        font-size: 20px;
                    }

                    &:last-of-type {
                        color: #353C49;
                        font-size: 40px;
                    }
                }
            }

            .privileges {
                margin-top: 20px;
                background: #EFF2F5;
                border-radius: 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 32px;

                ul {
                    padding-left: 20px;
                    list-style: none;
                    margin-bottom: 0;
                }

                @media (max-width: 767px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                &>div {
                    &:first-of-type {
                        color: #4880EE;
                        font-size: 20px;
                    }

                    &:last-of-type {
                        li {
                            color: #353C49;
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .right-content {
            @media (max-width: 767px) {
                grid-row-start: 1;
                grid-row-end: 2;
            }

            .ant-radio-group {
                width: 100%;
                background: #E1EBFE;
                border-radius: 28px;
                padding: 3px;

                .ant-radio-button-wrapper {
                    width: 50%;
                    text-align: center;
                    color: #505866;
                    font-size: 18px;
                    background: #E1EBFE;
                    border: none;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 28px;

                    &:not(:first-child)::before {
                        display: none;
                    }

                    &-checked {
                        border: none;
                        box-shadow: 0px 4px 4px rgba(72, 128, 238, 0.3);
                        border-radius: 28px;
                        background: #4880EE;
                        color: #fff
                    }
                }
            }

            .stake {
                p {
                    color: #353C49;
                    font-size: 20px;
                    font-weight: 500;
                    text-align: center;
                    margin: 10px 0;
                }

                .ant-btn {
                    border-radius: 8px;
                    height: 50px;
                    font-size: 18px;
                    border: none;

                    @media (max-width: 767px) {
                        height: 40px;
                    }

                    &[disabled] {
                        background: #B8C5D8;
                        color: #fff;
                    }
                }

                .amount {
                    background: #F6F6F6;
                    border-radius: 16px;
                    margin-bottom: 20px;

                    &>div {
                        display: flex;
                        margin: 0 10px;
                        padding: 10px 0;
                        justify-content: space-between;
                        color: #505866;
                        font-size: 16px;
                        align-items: center;

                        &:first-of-type {
                            border-bottom: 1px solid #CFD6E4;

                            &>div {
                                width: 50%;
                                text-align: center;
                            }
                        }

                        &:last-of-type {
                            color: #1A1E27;
                            font-size: 18px;

                            .ant-btn {
                                background: #E1EBFE;
                                border-radius: 20px;
                                color: #1A1E27;
                                height: 40px;
                                border: none;
                                font-size: 18px;
                                font-weight: 500;
                            }

                            .ant-input-number {
                                font-size: 24px;
                                background: none;
                                box-shadow: none;
                                border: none;
                                text-align: center;
                                width: 100%;

                                .ant-input-number-handler-wrap {
                                    display: none;
                                }
                            }

                            .ant-input-number-input {
                                text-align: center;
                            }

                            &>div {
                                &:nth-of-type(2) {
                                    border-right: 1px solid #CFD6E4;
                                    margin-right: 10px;
                                    padding-right: 10px;
                                }
                            }
                        }
                    }
                }
            }

            .tiers {
                margin-top: 20px;

                .amount {
                    background: #E1EBFE;
                    border-radius: 16px;
                    display: flex;
                    justify-content: space-between;
                    padding: 10px 40px;

                    &>div {
                        &:first-of-type {
                            width: 40%;
                        }

                        &:nth-of-type(2) {
                            width: 20%;
                        }

                        &:last-of-type {
                            width: 40%;
                            text-align: right;
                        }
                    }
                }

                .amount-content {
                    background: #F6F6F6;
                    border-radius: 16px;
                    padding: 10px 40px;
                    margin-top: 16px;

                    &>div {
                        display: flex;
                        padding: 5px 0;
                        justify-content: space-between;
                        width: 100%;

                        &>div {
                            &:first-of-type {
                                width: 40%;
                            }

                            &:nth-of-type(2) {
                                width: 20%;
                            }

                            &:last-of-type {
                                width: 40%;
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
}

.buyCBKModal {
    .ant-modal-header {
        border-bottom: none;

        .ant-modal-title {
            display: flex;
            align-items: center;

            svg {
                background: #2175FF;
                height: 24px;
                width: 24px;
                border-radius: 50%;
                padding: 3px;
                margin-right: 10px;
            }
        }
    }

    .ant-modal-body {
        padding-top: 0;
    }

    .item {
        display: flex;
        justify-content: space-between;
        background: #EAEDF0;
        border-radius: 8px;
        padding: 14px 20px;
        align-items: center;
        color: #4880EE;
        font-size: 16px;
        font-weight: 500;
        margin: 10px 0;

        &:hover {
            text-decoration: none;
        }
    }
}

.approval_text {
    margin-bottom: 10px;
}
@primary-color: #4880EE;@border-radius-base: 8px;