.projects {
  min-height: calc(100vh - 90px);

  .ant-layout-content {
    margin-top: -180px;
    margin-bottom: 60px;
    position: relative;
    z-index: 2;

    h1 {
      font-size: 24px;
      color: #000;
      margin: 60px 0 30px;

      &:first-of-type {
        color: #fff;
      }
    }
  }

  .first-content {
    background: url("../../assets/img/bg1.jpg") no-repeat,
      linear-gradient(90deg, #4880ee 0%, #155be3 100%);
    background-size: 100%;
    height: 430px;
    background-position: 0 100%;
    padding-top: 64;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 767px) {
      background: linear-gradient(90deg, #4880ee 0%, #155be3 100%);
    }

    .ant-layout-content {
      margin-top: 0;
      margin-bottom: 0;
    }

    // opacity: 0.2;
    // &::after{
    //     position: absolute;
    //     content: '';
    //     width: 100%;
    //     height: 100%;
    //     top: 0;
    //     left: 0;
    //     z-index: 10;
    //     background: url('../../assets/img/bg1.png') no-repeat;
    //     background-size: 100%;
    //     background-position: 100% 0;
    //     // opacity: 0.2;
    // }
    h1 {
      font-weight: bold;
      font-size: 24px;
      color: #fff;
      margin: 0;
    }

    p {
      font-size: 18px;
      color: #fff;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }

    .search {
      margin: 30px 0;

      .ant-input-affix-wrapper {
        border: none;
        height: 48px;
        padding: 0;

        &:focus,
        .ant-input-affix-wrapper-focused {
          box-shadow: none;
        }

        input {
          font-size: 16px;
          padding: 0 15px;
        }
      }

      .ant-input-affix-wrapper-focused {
        box-shadow: none;
      }

      .ant-input-suffix {
        width: 15px;
      }

      .ant-input-group-addon {
        border: none;
        background: #fff;

        .ant-input-search-button {
          border: none;
          background: none;
          box-shadow: none;
          outline: 0;
          display: flex;
          align-items: center;
        }

        svg {
          font-size: 24px;
        }
      }
    }
  }

  .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  .ant-skeleton {
    padding: 17px;
    border-radius: 16px;
    background-color: #fff;
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 17px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    cursor: pointer;

    @media (max-width: 767px) {
      width: 100%;
    }

    .ant-tag {
      position: absolute;
      top: 17px;
      right: 17px;
      width: 160px;
      text-align: center;
      color: #fff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      border: none;
      height: 30px;
      line-height: 30px;
      font-weight: 500;
      font-size: 18px;
      margin: 0;
      text-transform: capitalize;

      &.open {
        background: linear-gradient(90deg, #4880ee -20.5%, #7142ea 111.5%);
      }

      &.soon {
        background: linear-gradient(90deg, #4880ee -20.5%, #d242ea 111.5%);
      }

      &.closed {
        background: linear-gradient(90deg, #285abc -20.5%, #4880ee 111.5%);
      }
    }

    .img {
      border-radius: 16px;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 100%;

      .noBanner {
        background: #596f9b;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 50px;
          width: auto;

          @media (max-width: 767px) {
            height: 30px;
          }
        }
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .avatar {
      margin: 30px 0 0;
      display: flex;
      align-items: center;

      .ant-avatar {
        border-radius: 16px;
        margin-right: 15px;
        background: #4880ee;

        &.ant-avatar-image {
          background: transparent !important;
        }

        svg {
          width: 100%;
        }
      }

      h2 {
        font-size: 16px;
        color: #1a1e27;
        line-height: 19px;
        font-weight: 700;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h3 {
        font-size: 16px;
        color: #1a1e27;
        font-weight: normal;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .intro {
      p {
        font-size: 12px;
        color: #1a1e27;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
      }

      .ant-progress {
        margin: 16px 0 12px;
      }

      .ant-progress-show-info .ant-progress-outer {
        margin: 0;
        padding: 0;
      }

      .ant-progress-inner,
      .ant-progress-bg {
        height: 12px !important;
      }

      .ant-progress-text {
        display: none;
      }

      .ant-progress-status-success .ant-progress-text {
        color: #000;
      }

      ul {
        margin: 0;
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 8px;

        li {
          list-style: none;

          & .icon {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;
            width: 32px;
            height: 32px;
            border-radius: 18px;
            overflow: hidden;
            transition: 0.2s;
            background-color: #eee;

            &.medium {
              padding: 0;
            }

            &:hover {
              transform: scale(1.1);
              background-color: #cecece;
            }

            & svg {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      .footer {
        display: flex;
        justify-content: space-between;
        font-size: 12px;

        .ant-statistic-content {
          font-size: 10px;
        }
      }
    }
  }
}

.project-detail {
  padding-top: 64px;
  min-height: calc(100vh - 85px);

  .ant-layout-content {
    padding: 50px;

    @media (max-width: 767px) {
      padding: 20px;
    }
  }

  .back-btn {
    border: 1px solid #4880ee !important;
    width: 280px;
    background: none;
    text-align: left;
    height: 40px;
    line-height: 38px;
    padding: 0 20px;
  }

  .avatar {
    margin: 24px 0 50px;
    height: 180px;
    position: relative;

    .info {
      display: flex;
      align-items: center;

      p {
        font-size: 16px;
        color: #1a1e27;
        margin-bottom: 0;
        font-size: 12px;
        color: #1a1e27;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;

        @media (max-width: 767px) {
          -webkit-line-clamp: 2;
          font-size: 12px;
        }
      }
    }

    @media (max-width: 767px) {
      background: #fff;
      padding: 20px;
      border-radius: 16px;
      height: auto;
    }

    & > div {
      height: 100%;

      &:last-of-type {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    .ant-avatar {
      border-radius: 16px;
      margin-right: 28px;
      width: 180px;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #4880ee;

      &.ant-avatar-image {
        background: transparent !important;
      }

      svg {
        width: 100px;
        height: auto;
      }

      @media (max-width: 767px) {
        width: 66px;
        height: 66px;
        margin-right: 15px;
      }
    }

    h2 {
      font-size: 32px;
      color: #1a1e27;
      font-weight: 500;

      @media (max-width: 767px) {
        font-size: 18px;
      }
    }

    .detail-h5 {
      margin-top: 15px;
      display: none;

      @media (max-width: 767px) {
        display: block;
      }

      .img {
        border-radius: 16px;
        // height: 150px;
        width: 100%;
        margin-bottom: 20px;
        justify-content: center;
        display: flex;
        align-items: center;

        .noBanner {
          height: 100px;
          background: linear-gradient(180deg, #cb33d2 0%, #4d3eda 100%);
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            height: 30px;
            width: auto;
          }
        }

        img {
          object-fit: contain;
          width: 100%;
        }
      }

      .ant-btn {
        background: #f4f4f4;
        border-radius: 8px;

        &[disabled] {
          background: #b8c5d8;
          color: #fff;
        }
      }

      .ant-collapse {
        border: none;
      }

      .ant-collapse > .ant-collapse-item,
      .ant-collapse-content {
        border: none;
      }

      .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        height: 32px;
        line-height: 1;
      }

      .ant-collapse
        > .ant-collapse-item
        > .ant-collapse-header
        .ant-collapse-arrow {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .media {
      position: absolute;
      top: 0;
      right: 0;

      @media (max-width: 767px) {
        top: 5px;
        right: 5px;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;

        li {
          display: block;
          margin: 0 10px;

          a {
            display: flex;
            align-items: center;
          }

          svg {
            display: block;
            fill: #a8b0c1;
            height: auto;
            width: 24px;
          }
        }
      }
    }
  }
}

.refresh {
  width: 25px;
  height: 25px;
  background-color: #bcbcbc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

@primary-color: #4880EE;@border-radius-base: 8px;