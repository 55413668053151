.web3-status{
    .ant-btn{
        background: none;
        color: #fff;
        display: flex;
        align-items: center;
        border: none;
        @media (max-width: 992px) {
            font-size: 12px;
        }
        .wallet{
            height: 22px;
            width: auto;
            fill: none;
            @media (max-width: 992px) {
                height: 18px;
            }
        }
    }
    &.light{
        .ant-btn{
            color: #000;
            border: 1px solid #000;
        }
    }
    .account-content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #124FC7;
        border-radius: 16px;
        height: 40px;
        color: #fff;
        cursor: pointer;
        .ethBalance{
            color: #fff;
            padding: 0 10px;
        }
        .ant-btn{
            background: #81ABFF;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            border-radius: 16px;
        }
    }
}
@primary-color: #4880EE;@border-radius-base: 8px;